<template>
  <div class="bg-blue-50 rounded-lg mx-auto p-4">
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
    </div>
    <div v-else>
      <!-- Controls section -->
      <div class="mb-6 space-y-4">
        <!-- Search and filters -->
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div class="col-span-1">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search by name..."
              class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              @input="debouncedSearch"
            />
          </div>
          <div class="col-span-1">
            <select
              v-model="sortBy"
              class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="name">Sort by Name</option>
              <option value="date">Sort by Date</option>
            </select>
          </div>
          <div class="col-span-1">
            <select
              v-model="sortOrder"
              class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
          <div class="col-span-1">
            <select
              v-model="showArchived"
              class="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="active">Active Results</option>
              <option value="archived">Archived Results</option>
              <option value="all">All Results</option>
            </select>
          </div>
        </div>

        <!-- Stats summary -->
        <div class="bg-white p-4 rounded-lg shadow-sm">
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="text-center">
              <p class="text-gray-600 text-sm">Total Results</p>
              <p class="text-xl font-bold">{{ filteredResults.length }}</p>
            </div>
            <div class="text-center">
              <p class="text-gray-600 text-sm">Last 7 Days</p>
              <p class="text-xl font-bold">{{ lastSevenDaysCount }}</p>
            </div>
            <div class="text-center">
              <p class="text-gray-600 text-sm">This Month</p>
              <p class="text-xl font-bold">{{ thisMonthCount }}</p>
            </div>
            <div class="text-center">
              <p class="text-gray-600 text-sm">Total Users</p>
              <p class="text-xl font-bold">{{ uniqueUsersCount }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Results table -->
      <div class="bg-white rounded-lg shadow overflow-hidden">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-if="sortedResults.length === 0">
              <td colspan="3" class="px-6 py-4 text-center text-gray-500">
                No results found
              </td>
            </tr>
            <tr v-for="entry in sortedResults" :key="entry.$id" class="hover:bg-gray-50">
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">{{ entry.Name }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-500">
                  {{ formatDate(entry.$createdAt) }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button
                  @click="viewResult(entry.$id)"
                  class="text-blue-600 hover:text-blue-900 mr-2"
                >
                  View
                </button>
                <button
                  @click="toggleArchive(entry)"
                  class="text-gray-600 hover:text-gray-900"
                  :class="{ 'text-amber-600 hover:text-amber-900': entry.archived }"
                >
                  {{ entry.archived ? 'Unarchive' : 'Archive' }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";

const authStore = useAuthStore();
const router = useRouter();

// State
const kurtzAllResults = ref([]);
const loading = ref(false);
const searchQuery = ref("");
const sortBy = ref("date");
const sortOrder = ref("desc");
const showArchived = ref('active');

// Debouncing
let debounceTimer;
const debouncedSearch = () => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => {}, 300);
};

// Date formatting
const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

// Computed properties
const filteredResults = computed(() => {
  let results = kurtzAllResults.value;

  // Filter by search query
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase();
    results = results.filter((entry) =>
      entry.Name?.toLowerCase().includes(query)
    );
  }

  // Filter by archive status
  if (showArchived.value === 'active') {
    results = results.filter(entry => !entry.archived);
  } else if (showArchived.value === 'archived') {
    results = results.filter(entry => entry.archived);
  }

  return results;
});

const sortedResults = computed(() => {
  return [...filteredResults.value].sort((a, b) => {
    const aValue = sortBy.value === "name" ? a.Name : a.$createdAt;
    const bValue = sortBy.value === "name" ? b.Name : b.$createdAt;

    if (sortOrder.value === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });
});

// Stats computations
const lastSevenDaysCount = computed(() => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  return filteredResults.value.filter(
    (entry) => new Date(entry.$createdAt) > sevenDaysAgo
  ).length;
});

const thisMonthCount = computed(() => {
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(1);
  return filteredResults.value.filter(
    (entry) => new Date(entry.$createdAt) > firstDayOfMonth
  ).length;
});

const uniqueUsersCount = computed(() => {
  const uniqueUsers = new Set(filteredResults.value.map((entry) => entry.userId));
  return uniqueUsers.size;
});

// Actions
const viewResult = (id) => {
  router.push({ name: "KurtzResultDetail", params: { id } });
};

const downloadPDF = async (entry) => {
  await authStore.generatePDF(entry);
};

const toggleArchive = async (entry) => {
  try {
    await authStore.toggleArchiveResult(entry.$id, !entry.archived);
  } catch (error) {
    console.error('Failed to toggle archive status:', error);
  }
};

onMounted(async () => {
  loading.value = true;
  await authStore.fetchAllKurtzResults();
  kurtzAllResults.value = authStore.kurtzAllResults;
  loading.value = false;
});

onUnmounted(() => {
  clearTimeout(debounceTimer);
});
</script>

<style scoped>
/* Add any custom styling here */
</style>
