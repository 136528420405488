<template>
  <div class="max-w-4xl mx-auto p-6 bg-white">
    <div
      class="flex items-center justify-start mb-4 space-x-3 cursor-pointer"
      
    >
      <!-- Toggle switch for showing/hiding search bar -->
      <Switch
        v-model="enabled"
        :class="enabled ? 'bg-green-600' : 'bg-gray-200'"
        class="relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200"
      >
        <span class="sr-only">Show/Hide Search Bar</span>
        <span
          :class="enabled ? 'translate-x-6' : 'translate-x-1'"
          class="inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200"
        />
      </Switch>

      <label class="text-gray-700"> Enable Dictionary Search </label>
    </div>

    <!-- Search bar, shown only if enabled -->
    <div v-if="enabled" class="mb-4">
      <input
        type="text"
        v-model="search"
        placeholder="Search for a word"
        @change="searchWord"
        class="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
      />
    </div>

    <!-- Search results -->
    <div v-if="searchResult" class="mt-4">
      <h3 class="text-xl font-semibold mb-2">Definition:</h3>
      <p class="text-gray-700">{{ searchResult }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Switch } from "@headlessui/vue";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const search = ref("");
const searchResult = ref("");
const enabled = ref(true); // State for showing/hiding the search bar

// Search for a word and display the result
const searchWord = async () => {
  if (search.value.trim() !== "") {
    const definition = await authStore.fetchDictionary(search.value.trim());
    searchResult.value = definition || "Definition not found";
  } else {
    searchResult.value = "";
  }
};
</script>

<style scoped>
/* Add styles for the container and layout */
</style>
