<!-- src/components/Logos.vue -->
<template>
  <div class="grid lg:grid-cols-2 grid-cols-4 items-center space-x-3 max-w-lg mx-auto">
    <img class="col-span-1" src="../assets/logo.png" alt="Protocase Inc" />
    <img class="" src="../assets/45drives-brand.png" alt="45Drives" />
  </div>
</template>

<script setup>
</script>

<style scoped>
/* Add any additional styles here */
</style>