<template>
    <div class="">
        <!-- Header -->
        <Header  />


        <!-- Main Content -->
        <main class="mt-10 sm:-mt-24 pb-8">
            <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <slot />
            </div>
        </main>



        <!-- Footer -->
        <Footer />
    </div>
</template>

<script setup>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();





</script>