<template>
  <div v-if="isLoading">
    <Spinner />
    <!-- Add a loading spinner during async operations -->
  </div>

  <div v-else>
    <div
      v-if="isAccepted"
      class="mt-32 max-w-6xl mx-auto p-6 bg-white rounded-md shadow-lg"
    >
      <h2>You've already accepted the policy</h2>
      <a :href="pdfLink" target="_blank" class="text-blue-500"
        >View your policy acceptance PDF</a
      >
    </div>

    <div v-else>
      <div
        id="policy-content"
        class="mt-32 max-w-6xl mx-auto p-6 bg-white rounded-md shadow-lg"
      >
        <h2 class="text-2xl font-semibold mb-4">Employee Handbook</h2>

        <div class="m-10 text-green-400" v-if="showPrompt">
          <p class="text-sm">
            Please scroll to the bottom of the policy to accept the terms.
          </p>
          <p class="text-sm">
            By accepting the policy, you agree to the terms and conditions outlined above.
          </p>
        </div>

        <div v-if="showPrompt" class="p-4 border border-gray-300 rounded-md">
          <div v-html="policiesContent">
            
          </div>
        </div>
        <div v-if="showPrompt" class="mt-4">
          <h2 class="text-xl font-semibold mt-4">Acknowledgment to Employee Handbook</h2>
          <p class="mt-2 text-sm">
            I, <span class="font-bold">{{ name }}</span
            >, acknowledge that on <span class="font-bold">{{ currentDate }}</span
            >, I received a copy of the Protocase, USA Inc.’s (“the Company”) Employee
            Handbook (“Handbook”) and that I read it, understood it, and agree to comply
            with it. I understand that the Company has the maximum discretion permitted by
            law to interpret, administer, change, modify, or delete the rules,
            regulations, procedures, and benefits contained in the Handbook at any time
            with or without notice. No statement or representation by a supervisor or
            manager or any other employee, whether oral or written, can supplement or
            modify this Handbook. Changes can only be made if approved in writing by NAME.
            I also understand that any delay or failure by the Company to enforce any
            rule, regulation, or procedure contained in the Handbook will not constitute a
            waiver of the Company’s right to do so in the future.
          </p>
          <p class="mt-2 text-sm">
            I understand that neither this Handbook nor any other communication by a
            management representative or any other employee, whether oral or written, is
            intended in any way to create a contract of employment. I understand that,
            unless I have a written employment agreement signed by an authorized Company
            representative, I am employed at-will and this Handbook does not modify my
            at-will employment status. If I have a written employment agreement signed by
            an authorized Company representative and this Handbook conflicts with the
            terms of my employment agreement, I understand that the terms of my employment
            agreement will govern.
          </p>

          <button
            @click="acceptPolicy"
            v-if="showPrompt"
            class="inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mt-4"
          >
            Accept Policy
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Success modal -->
  <div
    v-if="showSuccessModal"
    class="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white p-6 rounded-lg shadow-lg text-center">
      <h2 class="text-2xl font-semibold text-green-500">Success!</h2>
      <p class="mt-4">You've signed the Policy Package!</p>
     
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useAuthStore } from "@/stores/auth";
import { database, storage, ID, Query } from "@/lib/appwrite";
import html2pdf from "html2pdf.js";
import Spinner from "@/components/Spinner.vue";
import { useRouter } from "vue-router";
import { marked } from "marked";

const router = useRouter();
const authStore = useAuthStore();
const showPrompt = ref(false);
const name = authStore.user?.name.replace(/\s+/g, "_");
const userId = authStore.user?.$id;
const isLoading = ref(false);
const currentDate = new Date().toLocaleDateString();
const policiesContent = ref("");
const isAccepted = ref(false);
const showSuccessModal = ref(false);
const pdfLink = ref("");

// Enhanced function to convert DokuWiki syntax to HTML with TailwindCSS classes
const convertDokuWikiToHTMLWithTailwind = (text) => {
  // Convert headers with TailwindCSS classes
  text = text.replace(/^======\s*(.+?)\s*======$/gm, '<h1 class="text-3xl font-bold mb-4">$1</h1>');
  text = text.replace(/^=====\s*(.+?)\s*=====$/gm, '<h2 class="text-2xl font-semibold text-gray-700 mb-3">$1</h2>');
  text = text.replace(/^====\s*(.+?)\s*====$/gm, '<h3 class="text-xl font-semibold text-gray-700 mb-2">$1</h3>');
  text = text.replace(/^===\s*(.+?)\s*===$/gm, '<h4 class="text-lg font-semibold text-gray-700 mb-1">$1</h4>');
  text = text.replace(/^==\s*(.+?)\s*==$/gm, '<h5 class="text-base font-semibold text-gray-700">$1</h5>');

  // Convert unordered lists with TailwindCSS classes
  text = text.replace(/^\s*[*-]\s+(.+)$/gm, '<li class="text-gray-600">$1</li>'); // List item
  text = text.replace(/(<li.+<\/li>)/g, '<ul class="list-disc list-inside space-y-2 ml-6">$1</ul>'); // Wrap list items in <ul>

  // Convert bold and italic syntax
  text = text.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');  // Bold
  text = text.replace(/\/\/(.+?)\/\//g, '<em>$1</em>');          // Italics

  // Wrap paragraphs with TailwindCSS spacing
  text = text.replace(/\n{2,}/g, '</p><p class="text-gray-600 mt-4">'); // Separate paragraphs
  text = `<p class="text-gray-600 mt-4">${text}</p>`; // Wrap all content

  return text;
};

// Load and display policies
const loadPolicies = async () => {
  try {
    const response = await fetch("/assets/policies.txt");
    if (response.ok) {
      let text = await response.text();
      policiesContent.value = convertDokuWikiToHTMLWithTailwind(text);
    } else {
      console.error("Failed to load policies:", response.statusText);
    }
  } catch (error) {
    console.error("Error loading policies:", error);
  }
};



// Function to handle policy acceptance and PDF generation
const acceptPolicy = async () => {
  isLoading.value = true;
  try {
    // Ensure DOM is fully rendered before generating PDF
    // await nextTick();

    const element = document.querySelector("#policy-content");
    const opt = {
      margin: 1,
      filename: `${name}_${userId}_policy.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Generate PDF as Blob after ensuring DOM is ready
    const pdfBlob = await html2pdf().from(element).set(opt).output("blob");

    // Upload PDF to Appwrite Storage
    const file = new File([pdfBlob], `${name}_${userId}_policy.pdf`, {
      type: "application/pdf",
    });
    const response = await storage.createFile(
      "66fc3a2f000d50ce40d5", // Your Appwrite storage bucket ID
      ID.unique(), // Generate a unique file ID
      file // The PDF Blob
    );

    // Save policy acceptance in the database
    await database.createDocument(
      "66fc09e80002d737e7f7",
      "66fc3948001859ac7cec",
      "unique()",
      {
        user: authStore.user?.name || name,
        userId: authStore.user?.$id,
        accepted: true,
        fileId: response.$id, // Save the file ID in the document
      }
    );

    authStore.success = "Policy accepted successfully!";
    authStore.error = "";

    // Load the stored PDF after acceptance
    const fileView = await storage.getFileView("66fc3a2f000d50ce40d5", response.$id);
    pdfLink.value = fileView;

    // Hide the prompt after acceptance
    showPrompt.value = false;

    // Set the accepted flag to true
    isAccepted.value = true;

    // Show the success modal
    showSuccessModal.value = true;

    // Redirect to the home page after acceptance after 3 seconds
    setTimeout(() => {
      showSuccessModal.value = false;
      router.push("/");
    }, 3000);

  } catch (error) {
    authStore.error = "Failed to accept policy.";
    authStore.success = "";
  } finally {
    isLoading.value = false;
  }
};

// Check if the user has already accepted the policy
const alreadyAccepted = async () => {
  const query = [Query.equal("userId", userId), Query.equal("accepted", true)];

  const response = await database.listDocuments(
    "66fc09e80002d737e7f7",
    "66fc3948001859ac7cec",
    query
  );
  isAccepted.value = response.documents.length > 0;

  // Load the stored PDF if the user has already accepted the policy
  if (isAccepted.value) {
    const fileId = response.documents[0].fileId;
    const file = await storage.getFileView("66fc3a2f000d50ce40d5", fileId);
    pdfLink.value = file;
    showPrompt.value = false;
  } else {
    showPrompt.value = true;
  }
};

// Check if the user has accepted the policy when component mounts
onMounted(async () => {
  await authStore.init();
  await alreadyAccepted();
  await loadPolicies();
});
</script>
