<template>
  <Layout>
    <router-view />
  </Layout>
  <Toast />
</template>

<script setup>
import Layout from '@/components/Layout.vue';
import { onMounted, onUnmounted } from 'vue';
import Toast from '@/components/common/Toast.vue';
import { useAuthStore } from '@/stores/auth';

onUnmounted(() => {
    const store = useAuthStore();
    store.cleanup();
});

</script>