<template>
  <div>
    <form @submit.prevent="sendMagicURL" class="space-y-6">
      <div>
        <!-- note  -->
        <p class="text-sm text-gray-500">
          Enter your email address below to receive a magic URL to log in.
        </p>

        <input
          v-model="email"
          id="email"
          type="email"
          placeholder="Enter your email"
          class="p-2 mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div class="flex items-center justify-end">
        <button
          type="submit"
          class="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Request a login link
        </button>
      </div>
    </form>

    <!-- Success message -->
    <p
      v-if="authStore.success"
      class="mt-4 text-green-700 font-semibold bg-green-200 rounded p-2"
    >
      {{ authStore.success }}
    </p>

    <!-- Error message -->
    <p
      v-if="authStore.error"
      class="mt-4 text-red-700 font-semibold bg-red-200 rounded p-2"
    >
      {{ authStore.error }}
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const email = ref("");

const sendMagicURL = async () => {
  await authStore.sendMagicURL(email.value);
  email.value = "";
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
