<template>
  <div class="max-w-2xl mx-auto p-6 bg-white rounded-md shadow-lg">
    <h2 class="text-2xl font-semibold mb-4">Invite a Candidate</h2>
    <form @submit.prevent="handleInviteMember" class="space-y-6">
      <div>
        <label for="email" class="block m-2 text-sm font-medium text-gray-700">Email </label>
        <input
          v-model="email"
          id="email"
          type="email"
          placeholder="Enter email"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="name" class="block m-2 text-sm font-medium text-gray-700"
          >Member Name
        </label>
        <input
          v-model="name"
          id="name"
          type="text"
          placeholder="Enter member name"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div class="flex items-center justify-end">
        <button
          type="submit"
          class="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Invite Member
        </button>
      </div>
    </form>
    <p v-if="authStore.error" class="mt-4 text-red-500">{{ authStore.error }}</p>
    <p
      v-if="authStore.success"
      class="mt-4 text-sm font-semibold leading-relaxed text-green-500"
    >
      {{ authStore.success }} Please check your inbox
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();

// Reactive state for form inputs
const teamId = ref("66faaabeea32d9d7d759");
const email = ref("");
const name = ref("");
const role = ref("candidate");



const handleInviteMember = async () => {
  // Call the inviteMember method from the auth store
  await authStore.inviteTeamMember(teamId.value, email.value, name.value, role.value, "candidate");

  // Clear form inputs
  email.value = "";
  name.value = "";
};
</script>
