<template>
  <div class="max-w-4xl mx-auto p-6 bg-white">
    <!-- Instructions -->
    <p class="text-sm text-gray-600 mb-8 text-center">
      <span class="font-semibold uppercase">Directions:</span> Below you will find a list of personal qualities and their definitions. Think about yourself and how much of that quality you currently possess. In the box after each word, please rate yourself on a scale from 1-10 (1 being the least, 10 being the most). After you have rated the quality, check the box labeled "Trait" or "Skill" to the right of that word. Check the <span class="font-semibold">
      Trait</span> box if you think that quality is something people are born with, or the <span class="font-semibold">Skill</span> box if you think that quality is something people can learn.
    </p>

    <!-- Adjective Checklist Form -->
    <form @submit.prevent="validateAndSubmit" class="space-y-4">
      <div
        v-for="(item, index) in adjectives"
        :key="index"
        class="grid grid-cols-1 sm:grid-cols-3 gap-4 items-center"
      >
        <label class="font-semibold">{{ item.adjective }}</label>

        <!-- Replace the select element with this text input -->
        <input
          type="text"
          v-model="item.rating"
          class="w-full p-2 border border-gray-300 rounded-lg"
          placeholder="Enter rating (1-10)"
        />

        <!-- Replace radio buttons with checkboxes -->
        <div class="flex items-center space-x-4">
          <label class="inline-flex items-center">
            <input
              type="checkbox"
              v-model="item.isTrait"
              class="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <span class="ml-2">Trait</span>
          </label>
          <label class="inline-flex items-center">
            <input
              type="checkbox"
              v-model="item.isSkill"
              class="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <span class="ml-2">Skill</span>
          </label>
        </div>
      </div>

      <!-- Next Button -->
      <div class="flex justify-end mt-8">
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits } from "vue";
import { useAuthStore } from "@/stores/auth";
import { ADJECTIVES } from "@/constants/kurtzConstants";

const emit = defineEmits(["valid-submit"]);

const authStore = useAuthStore();

// Update the ADJECTIVES structure in your constants or modify it here
const adjectives = ref([...ADJECTIVES].map(adj => ({
  ...adj,
  isTrait: false,
  isSkill: false
})));

// Watch changes in the adjectives array
watch(
  adjectives,
  (newVal) => {
    authStore.kurtzResults.adjectives = newVal;
  },
  { deep: true }
);

// Simplify validateAndSubmit to skip validation
const validateAndSubmit = () => {
  submitChecklist();
};

// Simplify submitChecklist
const submitChecklist = () => {
  emit("valid-submit");
};
</script>

<style scoped>
/* Add any custom styling here */
</style>
