<template>
  <div class="max-w-4xl mx-auto p-6 bg-white">
    <!-- Instructions -->
    <p class="text-sm text-gray-600 mb-8 text-center">
      <span class="font-semibold uppercase">Directions:</span> What follows is a list of descriptors. Please read them over carefully and think about yourself. Then mark the boxes in front of the words which you feel best describe you. All the words listed could apply to you at one time or another, but you should <span class="font-semibold">ONLY</span> mark the ones which describe you at least <span class="font-semibold">75%</span> of the time or more. Also, keep in mind there are no "good" or "bad" descriptors, just those which best personify you.
    </p>

    <!-- Descriptors Grid -->
    <form
      @submit.prevent="validateAndSubmit"
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
    >
      <div
        v-for="(descriptor, index) in descriptors"
        :key="index"
        class="flex items-center"
      >
        <input
          type="checkbox"
          :id="'descriptor-' + index"
          v-model="selectedDescriptors"
          :value="descriptor"
          class="mr-3 h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
        />
        <label :for="'descriptor-' + index" class="text-gray-800 cursor-pointer">{{
          descriptor.name
        }}</label>
      </div>

      <!-- Next Button -->
      <div class="flex justify-end mt-8 col-span-full">
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits } from "vue";
import { useAuthStore } from "../../stores/auth";
import { DESCRIPTORS } from "@/constants/kurtzConstants";


const emit = defineEmits(["valid-submit"]);

const authStore = useAuthStore();

// Descriptors List
const descriptors = ref([...DESCRIPTORS]);

const selectedDescriptors = ref([]);

// Add to kurtzResults in authStore on change of selectedDescriptors
watch(selectedDescriptors, (newVal) => {
  authStore.kurtzResults.descriptors = newVal;
});

// Validate form inputs
const validateAndSubmit = () => {
  submitDescriptors();
};

// Submit the descriptors
const submitDescriptors = () => {
  // Emit event to parent component
  emit("valid-submit");
};
</script>

<style scoped>
/* Add any custom styling */
input:checked + label {
  background-color: #e0f7fa;
  border-radius: 4px;
  padding: 4px;
}
</style>