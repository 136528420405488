// src/constants/kurtzConstants.js

export const DESCRIPTORS = [
    { name: "Risk-Taker", checked: false },
    { name: "Organizer", checked: false },
    { name: "Direct", checked: false },
    { name: "Independent", checked: false },
    { name: "Fast-Paced", checked: false },
    { name: "Generalist", checked: false },
    { name: "Self-Starting", checked: false },
    { name: "Bottom-Line Oriented", checked: false },
    { name: "Seeks Control", checked: false },
    { name: "Lead-Taker", checked: false },
    { name: "Results-Oriented", checked: false },
    { name: "Driven", checked: false },
    { name: "Charming", checked: false },
    { name: "Outspoken", checked: false },
    { name: "Likes Variety", checked: false },
    { name: "Emotional", checked: false },
    { name: "Persuasive", checked: false },
    { name: "Exacting", checked: false },
    { name: "Enthusiastic", checked: false },
    { name: "Competitive", checked: false },
    { name: "People-Oriented", checked: false },
    { name: "Colorful", checked: false },
    { name: "Impatient", checked: false },
    { name: "Promoter", checked: false },
    { name: "Systematic", checked: false },
    { name: "Mentor", checked: false },
    { name: "Streamlines Steps", checked: false },
    { name: "Patient Listener", checked: false },
    { name: "Easy-Going", checked: false },
    { name: "Routine-Oriented", checked: false },
    { name: "Tolerant", checked: false },
    { name: "Stubborn", checked: false },
    { name: "Builds Trust", checked: false },
    { name: "Coach", checked: false },
    { name: "Fair", checked: false },
    { name: "Networker", checked: false },
    { name: "Perfectionistic", checked: false },
    { name: "Precise Appearance", checked: false },
    { name: "Cautious", checked: false },
    { name: "Strong Value System", checked: false },
    { name: "Emotionally Private", checked: false },
    { name: "Thorough", checked: false },
    { name: "Detailed", checked: false },
    { name: "Persistent", checked: false },
    { name: "Quality-Minded", checked: false },
    { name: "Rules-Oriented", checked: false },
    { name: "Judgmental", checked: false },
    { name: "Diplomatic", checked: false },
];

export const ADJECTIVES = [
    { adjective: "Enthusiastic", rating: "", type: "", missing: false },
    { adjective: "Honest", rating: "", type: "", missing: false },
    { adjective: "Caring", rating: "", type: "", missing: false },
    { adjective: "Perseverant", rating: "", type: "", missing: false },
    { adjective: "Confident", rating: "", type: "", missing: false },
    { adjective: "Professional", rating: "", type: "", missing: false },
    { adjective: "Focused", rating: "", type: "", missing: false },
    { adjective: "Forward Thinking", rating: "", type: "", missing: false },
    { adjective: "Organized", rating: "", type: "", missing: false },
    { adjective: "Flexible", rating: "", type: "", missing: false },
    { adjective: "Goal Oriented", rating: "", type: "", missing: false },
    { adjective: "Prompt", rating: "", type: "", missing: false },
    { adjective: "Dependable", rating: "", type: "", missing: false },
    { adjective: "Assertive", rating: "", type: "", missing: false },
    { adjective: "Good Sense of Humor", rating: "", type: "", missing: false },
    { adjective: "Positive", rating: "", type: "", missing: false },
    { adjective: "Committed", rating: "", type: "", missing: false },
    { adjective: "Empathetic", rating: "", type: "", missing: false },
];

export const COLOR_SHEETS = [
    {
        color: "Green",
        colorClass: "text-green-500",
        description: [
            "Searching, learning, understanding are fun.",
            "I love puzzles, problems, and finding solutions.",
            "I like to work independently.",
            "Intelligence, justice, and fairness are important.",
            "I want to be correct - to do things right.",
            "It would be great fun to explore the universe.",
            "Once I've found the solution, others can put it into action.",
            "My calm exterior may hide some inner turmoil.",
            "I love to create a brand new idea.",
            "Being competent is absolutely essential.",
            "I want my brain to manage my emotions.",
        ],
        rank: null,
    },
    {
        color: "Blue",
        colorClass: "text-blue-500",
        description: [
            "Relationships are important to me.",
            "I want to have lots of friends - share and care.",
            "I have integrity, I'm authentic and unique.",
            "I like helping others become what they can be.",
            "Emotions are okay, and I show mine easily.",
            "People are lots more important than things.",
            "I enjoy flowers, music, romantic movies.",
            "I love to help people solve their problems.",
            "My hunches work, I'm very intuitive.",
            "Empathy and sympathy are both easy for me.",
            "I thrive on recognition and acceptance.",
            "I'm really good at motivating people.",
        ],
        rank: null,
    },
    {
        color: "Red",
        colorClass: "text-red-500",
        description: [
            "I like being free to do things my own way.",
            "Defeats are temporary.",
            "Life is a wonderful game - let's play.",
            "Where's the action (or the problem), let me at em!",
            "Variety and excitement are fun and stimulating.",
            "Give me a challenge - I'll handle it NOW!",
            "I love the spotlight - watch me perform.",
            "Let's find a new and different way to do it.",
            "Freedom is important, don't fence me in.",
            "Rules that don't make sense can be broken.",
            "Bells are for ringing, mountains for climbing.",
        ],
        rank: null,
    },
    {
        color: "Yellow",
        colorClass: "text-yellow-500",
        description: [
            "I value rules, tradition, and authority.",
            "I have a clear idea of what people should do.",
            "I am loyal, dependable, punctual, trustworthy.",
            "I handle details well, and I'm a hard worker.",
            "I am useful, productive, a contributor.",
            "I like to care for others - look out for them.",
            "I want to anticipate and prepare for the future.",
            "The home and family are the core of society.",
            "It's important to have rules, laws, and controls.",
            "I appreciate awards and public recognition.",
            "I provide stability within an organization.",
            "I demonstrate my love in practical ways.",
        ],
        rank: null,
    },
];

export const QUALITIES = [
    { quality1: 'SENSITIVE', quality2: 'TOUGH', selected: false },
    { quality1: 'COOPERATIVE', quality2: 'REBELLIOUS', selected: false },
    { quality1: 'STRONG', quality2: 'GENTLE', selected: false },
    { quality1: 'PROUD', quality2: 'HUMBLE', selected: false },
    { quality1: 'COWARDLY', quality2: 'COURAGEOUS', selected: false },
    { quality1: 'SELFISH', quality2: 'UNSELFISH', selected: false },
    { quality1: 'MATURE', quality2: 'PLAYFUL', selected: false },
    { quality1: 'INVOLVED', quality2: 'DETACHED', selected: false },
    { quality1: 'HUMOROUS', quality2: 'SERIOUS', selected: false },
    { quality1: 'LAZY', quality2: 'HARDWORKING', selected: false },
    { quality1: 'DISTANT', quality2: 'FRIENDLY', selected: false },
    { quality1: 'LOGICAL', quality2: 'CREATIVE', selected: false },
    { quality1: 'SELF-CONFIDENT', quality2: 'SELF-CRITICAL', selected: false },
    { quality1: 'CALM', quality2: 'EMOTIONAL', selected: false },
    { quality1: 'TRUSTING', quality2: 'CAUTIOUS', selected: false },
    { quality1: 'SHY', quality2: 'BOLD', selected: false },
    { quality1: 'DEPENDENT', quality2: 'INDEPENDENT', selected: false },
    { quality1: 'LOVING', quality2: 'ANGRY', selected: false },
    { quality1: 'IMPULSIVE', quality2: 'WELL-ORGANIZED', selected: false },
    { quality1: 'CONSISTENT', quality2: 'UNPREDICTABLE', selected: false },
    { quality1: 'HAPPY', quality2: 'DISCONTENT', selected: false },
    { quality1: 'MESSY', quality2: 'NEAT', selected: false },
    { quality1: 'OPTIMISTIC', quality2: 'PESSIMISTIC', selected: false }
];


export const ORGANIZATIONAL_VALUES =
    [
        { title: "1. Innovation", lowLabel: "Cautious", highLabel: "Risk taking", selected: 0 },
        {
            title: "2. Focus",
            lowLabel: "Concentrate on details",
            highLabel: "Big picture oriented",
            selected: 0,
        },
        {
            title: "3. Task Emphasis",
            lowLabel: "It's all about how we get there",
            highLabel: "It’s all about where we end up",
            selected: 0,
        },
        {
            title: "4. Cooperation",
            lowLabel: "Individual effort counts",
            highLabel: "Team effort counts",
            selected: 0,
        },
        {
            title: "5. Work Atmosphere",
            lowLabel: "Easygoing",
            highLabel: "Aggressive",
            selected: 0,
        },
        {
            title: "6. Goals",
            lowLabel: "Maintain status quo",
            highLabel: "Constantly grow",
            selected: 0,
        },
    ]
    ;

export const KURTZ_STEPS = [
    "descriptors",
    "adjectives",
    "colors",
    "wordSquare",
    "organizationalValues",
    "qualities",
];