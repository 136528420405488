<template>
  <div class="max-w-4xl mx-auto p-6 bg-white rounded-md shadow-lg">
    <h2 class="text-2xl font-semibold mb-4">User Acceptance Status</h2>
    <table class="min-w-full bg-white">
      <thead>
        <tr>
          <th
            class="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
            User</th>
          <th
            class="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
            Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td class="py-2 px-4 border-b border-gray-200">{{ user.name }}</td>
          <td class="py-2 px-4 border-b border-gray-200">
            <span v-if="user.accepted" class="text-green-500">Accepted</span>
            <span v-else class="text-red-500">Not Accepted</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { database } from '@/lib/appwrite';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();

const users = ref([]);

const fetchUsers = async () => {
  try {
    const response = await database.listDocuments('66fc09e80002d737e7f7', '66fc3948001859ac7cec');
    users.value = response.documents.map(doc => ({
      id: doc.$id,
      name: doc.user,
      accepted: doc.accepted,
    }));
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

onMounted(() => {
  // only admins can view this page
  if (!authStore.user?.role === 'admin') {
    router.push('/');
  } else {
    fetchUsers();
  }
});
</script>

<style scoped>
.table {
  @apply min-w-full bg-white;
}

.table th,
.table td {
  @apply py-2 px-4 border-b border-gray-200;
}

.table th {
  @apply bg-gray-100 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider;
}

.table td {
  @apply text-sm;
}
</style>