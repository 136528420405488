<template>
  <div
    class="setup-password-container max-w-md mx-auto p-6 bg-white rounded-md shadow-lg"
  >
    <h2 class="text-2xl font-semibold mb-4">Setup New Password</h2>
    <div v-if="authStore.error" class="text-red-500">{{ authStore.error }}</div>

    <!-- show name and email associated -->
    <div class="mb-4">
      <p class="text-gray-700">Name: {{ authStore.user?.name }}</p>
      <p class="text-gray-700">Email: {{ authStore.user?.email }}</p>
      <!-- labels -->
    </div>

    <form @submit.prevent="handleSubmit" class="space-y-6">
      <div>
        <label for="password" class="block text-sm font-medium text-gray-700"
          >New Password</label
        >
        <input
          v-model="password"
          id="password"
          type="password"
          placeholder="Enter new password"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          required
        />
      </div>

      <div>
        <label for="confirmPassword" class="block text-sm font-medium text-gray-700"
          >Confirm Password</label
        >
        <input
          v-model="confirmPassword"
          id="confirmPassword"
          type="password"
          placeholder="Confirm new password"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          required
        />
      </div>

      <button
        type="submit"
        class="inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Set New Password
      </button>

      <p v-if="authStore.success" class="text-green-500 mt-4">{{ authStore.success }}</p>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const password = ref("");
const confirmPassword = ref("");
const route = useRoute();
const router = useRouter();

onMounted(() => {
  console.log(authStore);
  if (authStore.userType == "candidate") {
    router.push("/kurtz");
  }
});

const handleSubmit = async () => {
  if (password.value !== confirmPassword.value) {
    authStore.error = "Passwords do not match.";
    return;
  }

  try {
    await authStore.setupPassword(password.value); // Call your store action to reset the password
    authStore.success = "Password has been reset successfully.";
    authStore.error = "";

    // Optionally redirect to the login page after password is reset
    setTimeout(() => {
      router.push("/");
      authStore.success = "";
    }, 3000);
  } catch (error) {
    authStore.error = "Failed to reset password.";
    authStore.success = "";
  }
};
</script>

<style scoped>
.setup-password-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
