<template>
  <div
    class="invite-member-container max-w-2xl mx-auto p-6 bg-white rounded-md shadow-lg"
  >
    <h2 class="text-2xl font-semibold mb-4">Invite a Team Member</h2>
    <form @submit.prevent="handleInviteMember" class="space-y-6">
      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">Email </label>
        <input
          v-model="email"
          id="email"
          type="email"
          placeholder="Enter email"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="name" class="block text-sm font-medium text-gray-700"
          >Member Name
        </label>
        <input
          v-model="name"
          id="name"
          type="text"
          placeholder="Enter member name"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="role" class="block text-sm font-medium text-gray-700">Role </label>
        <select
          v-model="role"
          id="role"
          class="p-2 m-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="" disabled>Select role</option>
          <option value="employer">Employee</option>
          <option value="candidate">Candidate</option>
        </select>
      </div>
      <div class="flex items-center justify-end">
        <button
          type="submit"
          class="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Invite Member
        </button>
      </div>
    </form>
    <p v-if="authStore.error" class="mt-4 text-red-500">{{ authStore.error }}</p>
    <p
      v-if="authStore.success"
      class="mt-4 text-sm font-semibold leading-relaxed text-green-500"
    >
      {{ authStore.success }} Please check your inbox
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();

// Reactive state for form inputs
const teamId = ref("66faaabeea32d9d7d759");
const email = ref("");
const name = ref("");
const role = ref(""); // Add role state

const handleInviteMember = async () => {
  await authStore.inviteTeamMember(teamId.value, email.value, name.value, role.value,"employee");
  email.value = "";
  name.value = "";
  role.value = ""; // Reset role
};
</script>

<style scoped>
.invite-member-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
