import { ref } from 'vue';

interface Toast {
  message: string;
  type: 'success' | 'error';
  id: number;
}

const toasts = ref<Toast[]>([]);
let nextId = 0;

// Change to default export
export default function useToast() {
  const showToast = (message: string, type: 'success' | 'error') => {
    const id = nextId++;
    const toast = { message, type, id };
    toasts.value.push(toast);

    // Auto-remove after 3 seconds
    setTimeout(() => {
      toasts.value = toasts.value.filter(t => t.id !== id);
    }, 3000);
  };

  const success = (message: string) => showToast(message, 'success');
  const error = (message: string) => showToast(message, 'error');

  return {
    toasts,
    success,
    error
  };
}
