import { account, ID, teams, database, functions, Query } from '@/lib/appwrite'
import { defineStore } from 'pinia'
import axios from 'axios';
const BASE_URL = import.meta.env.VITE_APP_URL;

// Add at the start after imports
const isMobileBrowser = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Helper functions for sessionStorage
const getFromSession = (key, defaultValue) => {
    try {
        const value = sessionStorage.getItem(key);
        return value ? JSON.parse(value) : defaultValue;
    } catch (error) {
        console.warn('Session storage failed, falling back to memory:', error);
        return defaultValue;
    }
};

const saveToSession = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

// Update handleRefresh utility
const handleRefresh = async (store) => {
    // Prevent multiple simultaneous refresh attempts
    if (store._refreshing) return;
    
    try {
        store._refreshing = true;

        // Skip refresh for guest or unauthenticated users
        if (store.isGuestKurtz || !store.isAuthenticated) {
            return;
        }

        // Try to get current session with error handling
        let session = null;
        try {
            session = await account.getSession('current');
        } catch (error) {
            // Handle specific error types
            if (error.code === 401 || 
                error.type === 'general_unauthorized_scope' ||
                error.name === 'TypeError') {
                store.isAuthenticated = false;
                store.user = null;
                store.isAdmin = false;
                store.userType = '';
                sessionStorage.clear();
                return;
            }
            // For network errors, don't clear the session
            if (error.message === 'Failed to fetch') {
                console.warn('Network error during session check, keeping current session');
                return;
            }
            throw error;
        }

        // Only proceed with refresh if we have a valid session
        if (session) {
            try {
                const user = await account.get();
                if (user) {
                    store.user = user;
                    saveToSession('user', user);
                }
            } catch (error) {
                console.warn('Failed to refresh user data:', error);
            }
        }
    } catch (error) {
        console.warn('Refresh failed:', error);
    } finally {
        store._refreshing = false;
    }
};

// Define the store

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        email: getFromSession('email', ''),
        password: '',
        isAuthenticated: getFromSession('isAuthenticated', false),
        isAdmin: getFromSession('isAdmin', false),
        user: getFromSession('user', {}),
        teamMembers: getFromSession('teamMembers', []),
        userType: getFromSession('userType', ''),
        error: null,
        success: null,
        isAcceptingInvite: false,
        loading: false, // Common state
        userProfile: getFromSession('userProfile', {}), // Common state
        kurtzCollectionId: '6707c9060011d26d519f',
        databaseId: '66fc09e80002d737e7f7',
        kurtzAllResults: getFromSession('kurtzAllResults', []),
        teamId: '66faaabeea32d9d7d759',
        // Kurtz Kit specific states
        kurtzProgress: getFromSession('kurtzProgress', {
            currentStep: 'descriptors', // tracks which step the user is on (e.g., descriptors, adjectives, colors)
            descriptors: [], // list of selected descriptors
            adjectives: [], // ratings for adjectives
            colors: [], // rankings of color sheets
            wordSquare: [], // rankings for the word square
            OrganizationalValues: [], // rankings for the Organizational Values
        }),
        kurtzResults: getFromSession('kurtzResults', {
            descriptors: [], // List of selected descriptors
        }), // Final results for Kurtz Kit
        isTestCompleted: getFromSession('isTestCompleted', false), // Track if the Kurtz Kit is completed
        isGuestKurtz: false, // Add new state for guest Kurtz access
    }),

    actions: {
        // Add new helper method to check role
        async checkRole(teamId, userId) {
            try {
                const response = await teams.listMemberships(teamId, [
                    Query.equal('userId', userId),
                    Query.limit(1)
                ]);

                if (response.memberships.length > 0) {
                    const membership = response.memberships[0];
                    return {
                        isOwner: membership.roles.includes('owner'),
                        isAdmin: membership.roles.includes('admin'),
                        isCandidate: membership.roles.includes('candidate'),
                        roles: membership.roles
                    };
                }
                return null;
            } catch (error) {
                console.error('Error checking role:', error);
                return null;
            }
        },

        // Update init method
        async init() {
            try {
                // Clear any existing refresh handler
                this.cleanup();

                // Check for guest Kurtz access first
                if (sessionStorage.getItem('kurtz_email')) {
                    this.isGuestKurtz = true;
                    return true;
                }

                // Create debounced refresh handler
                this._refreshHandler = debounce(() => handleRefresh(this), 1000);
                
                // Add new refresh handler
                window.addEventListener('focus', this._refreshHandler);

                // Initial session check with error handling
                try {
                    const session = await account.getSession('current');
                    if (session) {
                        const user = await account.get();
                        if (user) {
                            await this.login(user.email, null);
                        }
                    }
                } catch (error) {
                    if (error.code === 401 || error.message === 'Failed to fetch') {
                        this.isAuthenticated = false;
                        return false;
                    }
                    throw error;
                }

                return true;
            } catch (error) {
                console.error('Error initializing:', error);
                this.reset();
                return false;
            }
        },

        // Add reset method
        reset() {
            this.user = null;
            this.isAuthenticated = false;
            this.isAdmin = false;
            this.userType = '';
            this.isGuestKurtz = false;
            sessionStorage.clear();
        },

        // Add cleanup method for removing event listener
        cleanup() {
            if (this._refreshHandler) {
                window.removeEventListener('focus', this._refreshHandler);
                this._refreshHandler = null;
            }
        },
        
        // Add method to handle pending results
        async savePendingResults() {
            const pendingResults = sessionStorage.getItem('pending_kurtz_results');
            if (pendingResults) {
                this.kurtzResults = JSON.parse(pendingResults);
                await this.saveKurtzResults();
                sessionStorage.removeItem('pending_kurtz_results');
            }
        },

        // Replace getUserRoles with checkRole
        async getUserRoles(teamId, userId) {
            const roleInfo = await this.checkRole(teamId, userId);
            if (roleInfo) {
                this.user.labels = roleInfo.roles;
                return roleInfo.roles;
            }
            return null;
        },

        // Update hasRole to be more specific
        hasRole(requiredRole) {
            if (!this.user.labels) return false;
            if (requiredRole === 'admin') {
                return this.user.labels.includes('owner') || this.user.labels.includes('admin');
            }
            return this.user.labels.includes(requiredRole);
        },

        // Update login method with mobile handling
        async login(email, password) {
            try {
                this.error = null;
                this.loading = true;

                if (isMobileBrowser()) {
                    // Add small delay for mobile browsers to handle session creation
                    await new Promise(resolve => setTimeout(resolve, 500));
                }

                // Only create session if password is provided (normal login)
                // Skip session creation for init() calls
                if (password) {
                    await account.createEmailPasswordSession(email, password);
                }

                // Get user info
                const user = await account.get();
                this.user = user;
                saveToSession('user', this.user);

                // Early return for candidates
                if (user.labels && user.labels.includes('candidate')) {
                    this.isAuthenticated = true;
                    this.userType = 'candidate';
                    saveToSession('isAuthenticated', true);
                    saveToSession('userType', 'candidate');
                    return;
                }

                // Check role and fetch admin data if needed
                const roleInfo = await this.checkRole(this.teamId, user.$id);
                if (roleInfo) {
                    this.user.labels = roleInfo.roles;
                    this.isAdmin = roleInfo.isOwner || roleInfo.isAdmin;
                    saveToSession('isAdmin', this.isAdmin);

                    // Only fetch additional data if admin/owner
                    if (this.isAdmin) {
                        await Promise.all([
                            this.fetchAllKurtzResults(),
                            this.fetchTeamMembers(this.teamId)
                        ]);
                    }
                }

                this.isAuthenticated = true;
                saveToSession('isAuthenticated', true);

            } catch (error) {
                console.error('Login error:', error);
                this.user = null;
                this.isAuthenticated = false;
                this.error = isMobileBrowser() ? 
                    'Mobile browsers may require additional permissions. Please check your settings.' : 
                    'Failed to login. Please check your credentials.';
                throw error; // Propagate error for handling in components
            } finally {
                this.loading = false;
            }
        },

        async logout() {
            try {
                await account.deleteSession('current');
            } catch (error) {
                console.error('Failed to logout:', error);
            } finally {
                // Always clear state regardless of API call success
                this.user = null;
                this.isAuthenticated = false;
                this.isAdmin = false;
                this.userType = '';
                this.error = null;
                this.success = 'Logged out successfully!';
                sessionStorage.clear();
            }
        },

        // deleteUser
        async deleteUser(id) {
            try {
                console.log(id);
                // Execute Appwrite function where the path of the function is "/delete-user" and the method is "POST"
                const response = await functions.createExecution(
                    '671f9f2d0037c82bcbe7', // Function ID
                    JSON.stringify({ userId: id }), // BODY content as JSON string
                    false, // Set async to false for immediate execution
                    '/delete-user', // Optional path to pass to the function
                    'POST', // HTTP method
                    {} // Headers (empty in this case)
                );
                console.log(response);
            } catch (error) {
                console.error('Failed to delete user:', error);
            }
        },

        // Update generatePDF method
        async generatePDF(modalData) {
            try {
                // Convert modalData to JSON string to pass as payload
                const payload = JSON.stringify({ modalData });

                // Call the Appwrite function
                const response = await functions.createExecution('6724f8a50037e6e83355', payload);

                // Check for the response and handle file download if needed
                if (response.status === 'completed' && response.responseType === 'file') {
                    const pdfBlob = new Blob([response.fileData], { type: 'application/pdf' });
                    const url = URL.createObjectURL(pdfBlob);

                    if (isMobileBrowser()) {
                        // For mobile browsers, open in new tab
                        window.open(url, '_blank');
                    } else {
                        // Desktop behavior
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `${modalData.Name || 'Profile'}.pdf`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    URL.revokeObjectURL(url); // Clean up
                }
            } catch (error) {
                console.error('PDF generation failed:', error);
                this.error = isMobileBrowser() ? 
                    'PDF viewing may not work on some mobile browsers. Please try on desktop.' : 
                    'Failed to generate PDF';
            }
        },

        // send magic url
        async sendMagicURL(email) {
            this.error = null;
            this.success = null;
            try {
                const magicURL = `${BASE_URL}`;
                const token = await account.createMagicURLToken(
                    ID.unique(),
                    email,
                    magicURL,
                    false
                );
                this.error = null;
                this.success = "Magic URL sent successfully! Please check your inbox.";
                return token;
            }
            catch (error) {
                this.error = 'Failed to send magic URL.';
                console.error(error);
            }
        },

        async loginWithMagicURL(userId, secret) {
            this.error = null;
            this.success = null;
            try {
                await account.createSession(secret, userId);
                this.success = 'Invitation confirmed successfully!';
            } catch (error) {
                this.error = error.message;
                // console.error('Failed to confirm invitation:', error);
            }
        },

        async inviteTeamMember(teamId, email, name, role, userType) {
            if (!this.hasRole('admin') && !this.hasRole('owner')) {
                this.error = 'You do not have permission to perform this action.';
                return;
            }

            this.error = null;
            this.success = null;
            const rolesArray = [role];
            const url = `${BASE_URL}/confirmation/${userType}`;

            try {
                const response = await teams.createMembership(
                    teamId,
                    rolesArray,
                    email || null,
                    undefined,
                    undefined,
                    url,
                    name
                );

                this.success = `Invitation sent successfully!`;
                this.error = ''; // Clear previous error
                return response;
            } catch (err) {
                this.success = ''; // Clear previous success
                this.error = 'Error inviting member. Please try again.';
                console.error(err);
            }
        },

        async fetchTeamMembers(teamId) {
            this.loading = true;
            this.error = null;
            this.success = null;
            this.teamMembers = [];
            
            try {
                let offset = 0;
                const limit = 1000; // Maximum allowed by Appwrite
                let hasMore = true;
        
                while (hasMore) {
                    const response = await teams.listMemberships(teamId, [
                        Query.limit(limit),
                        Query.offset(offset)
                    ]);
                    
                    this.teamMembers = [...this.teamMembers, ...response.memberships];
                    
                    if (response.memberships.length < limit) {
                        hasMore = false;
                    } else {
                        offset += limit;
                    }
                }
                
                return this.teamMembers;
            } catch (error) {
                this.error = 'Failed to fetch team members.';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        async makeOwner(membershipId) {
            if (!this.hasRole('admin')) {
                this.error = 'You do not have permission to perform this action.';
                return;
            }

            this.error = null;
            this.success = null;
            try {
                const response = await teams.updateMembership(
                    this.teamId,
                    membershipId,
                    ['owner']
                );
                this.success = 'Owner added successfully!';
                // Refresh team members
                this.fetchTeamMembers(this.teamId);
                return response;
            } catch (error) {
                this.error = 'Failed to make owner.';
                console.error(error);
            }
        },

        async removeOwner(membershipId) {
            if (!this.hasRole('admin')) {
                this.error = 'You do not have permission to perform this action.';
                return;
            }

            this.error = null;
            this.success = null;
            try {
                const response = await teams.updateMembership(
                    this.teamId,
                    membershipId,
                    ['member']
                );
                this.success = 'Owner removed successfully!';
                // Refresh team members
                this.fetchTeamMembers(this.teamId);
                return response;
            } catch (error) {
                this.error = 'Failed to remove owner.';
                console.error(error);
            }
        },

        async acceptTeamInvitation(teamId, membershipId, userId, secret) {
            this.error = null;
            this.success = null;
            try {
                const result = await teams.updateMembershipStatus(
                    teamId,
                    membershipId,
                    userId,
                    secret
                );
                this.success = "Invitation accepted successfully!";
                this.isAuthenticated = true;
                this.user = result.user;
                this.isAdmin = this.user.labels.includes('admin');
                return result;
            } catch (error) {
                this.error = error.message;
                return error;
            }
        },

        async setupPassword(password) {
            this.error = null;
            this.success = null;

            try {
                await account.updatePassword(password);
                this.success = "Password set successfully!";
                this.error = '';
            } catch (err) {
                this.error = `Error setting password: ${err.message}`;
                this.success = '';
                console.error(err);
            }
        },

        async sendPasswordReset(email) {
            try {
                const response = await account.createRecovery(email, `${BASE_URL}/reset-password`);
                this.success = 'A password reset link has been sent to your email.';
                console.log(response);
            } catch (error) {
                this.error = 'Failed to send reset link.';
                console.error(error);
            }
        },

        async resetPassword(userId, secret, newPassword) {
            try {
                const response = await account.updateRecovery(userId, secret, newPassword, newPassword);

                if (response.code === 400) {
                    this.error = response.message;
                } else {
                    this.success = 'Password reset successfully!';
                }
            } catch (error) {
                this.error = 'Failed to reset password.';
                console.error(error);
            }
        },

        async updatePrefs(prefs) {
            try {
                const response = await account.updatePrefs(prefs);
                
            } catch (error) {
                console.error('Failed to update prefs:', error);
            }
        },

        async fetchUserProfile() {
            this.loading = true;
            try {
                const response = await account.get();
                this.userProfile = response;
                this.loading = false;
                saveToSession('userProfile', this.userProfile);
            } catch (error) {
                this.error = 'Failed to fetch user profile.';
                console.error(error);
                this.loading = false;
            }
        },

        async updateUserProfile(profileData) {
            this.loading = true;
            try {
                const response = await account.update(profileData);
                this.userProfile = response;
                this.success = 'Profile updated successfully!';
                this.loading = false;
                saveToSession('userProfile', this.userProfile);
            } catch (error) {
                this.error = 'Failed to update profile.';
                console.error(error);
                this.loading = false;
            }
        },

        async saveKurtzResults() {
            try {
                this.loading = true;
                this.error = null;

                if (!this.user?.$id) {
                    throw new Error('User not authenticated');
                }

                const kurtzData = {
                    userId: this.user.$id,
                    Name: this.user.name,
                    descriptors: JSON.stringify(this.kurtzResults.descriptors || []),
                    adjectives: JSON.stringify(this.kurtzResults.adjectives || []),
                    colorSheets: JSON.stringify(this.kurtzResults.colorSheets || []),
                    wordSquare: JSON.stringify(this.kurtzResults.wordSquare || []),
                    qualities: JSON.stringify(this.kurtzResults.qualities || []),
                    organizationalValues: JSON.stringify(this.kurtzResults.organizationalValues || []),
                    archived: false, // Add default archived state
                };

                const response = await database.createDocument(
                    this.databaseId,
                    this.kurtzCollectionId,
                    ID.unique(),
                    kurtzData
                );

                this.success = 'Kurtz Kit completed and saved successfully.';
                saveToSession('kurtzResults', this.kurtzResults);
                return response;
            } catch (error) {
                this.error = 'Failed to save Kurtz Kit results: ' + error.message;
                throw error;
            } finally {
                this.loading = false;
            }
        },

        async fetchAllKurtzResults() {
            this.loading = true;
            try {
                let allDocuments = [];
                let offset = 0;
                let hasMore = true;

                while (hasMore) {
                    const response = await database.listDocuments(
                        this.databaseId,
                        this.kurtzCollectionId,
                        [Query.offset(offset)]
                    );
                    
                    allDocuments = [...allDocuments, ...response.documents];
                    
                    if (response.documents.length === 0) {
                        hasMore = false;
                    } else {
                        offset += response.documents.length;
                    }
                }

                this.kurtzAllResults = allDocuments;
                saveToSession('kurtzAllResults', this.kurtzAllResults);
            } catch (error) {
                this.error = 'Failed to fetch Kurtz results.';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        async resolveUserName(userId) {
            try {
                const response = await account.get(userId);
                return response.name;
            } catch (error) {
                return 'Unknown';
            }
        },

        resetKurtzTest() {
            this.kurtzProgress = {
                currentStep: 'descriptors',
                descriptors: [],
                adjectives: [],
                colors: [],
                wordSquare: [],
            };
            this.isTestCompleted = false;
            this.kurtzResults = {};
            saveToSession('kurtzProgress', this.kurtzProgress);
            saveToSession('isTestCompleted', false);
            saveToSession('kurtzResults', this.kurtzResults);
        },

        async fetchDictionary(word) {
            try {
                const response = await axios.get(
                    `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
                );
                return response.data[0]?.meanings[0]?.definitions[0]?.definition || "";
            } catch (error) {
                return "Definition not available";
            }
        },

        async toggleArchiveResult(documentId, archived) {
            try {
                this.loading = true;
                // Create an update object that matches the schema
                const updateData = {
                    archived: archived
                };

                const response = await database.updateDocument(
                    this.databaseId,
                    this.kurtzCollectionId,
                    documentId,
                    updateData
                );
                
                // Update the local results
                const index = this.kurtzAllResults.findIndex(r => r.$id === documentId);
                if (index !== -1) {
                    this.kurtzAllResults[index].archived = archived;
                }
                
                this.success = `Result ${archived ? 'archived' : 'unarchived'} successfully`;
                saveToSession('kurtzAllResults', this.kurtzAllResults);
                return response;
            } catch (error) {
                this.error = 'Failed to update archive status';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        async fetchTeamMembersWithStatus(teamId) {
            try {
                // First fetch team members
                const members = await this.fetchTeamMembers(teamId);
                if (!members || !Array.isArray(members)) {
                    throw new Error('Failed to fetch team members');
                }

                // Then fetch Kurtz results
                await this.fetchAllKurtzResults();
                const results = this.kurtzAllResults;
                
                // Filter for candidates only and map their status
                const membersWithStatus = members
                    .filter(member => member.roles.includes('candidate')) // Only include candidates
                    .map(member => {
                        const result = Array.isArray(results) ? 
                            results.find(r => r.userId === member.userId) : 
                            null;

                        return {
                            $id: member.$id,
                            userId: member.userId,
                            teamId: member.teamId,
                            name: member.userName,
                            email: member.userEmail,
                            roles: member.roles,
                            joined: member.joined || null,
                            confirm: member.confirm,
                            kurtzStatus: result ? 'completed' : 'pending',
                            completedDate: result?.$createdAt || null,
                            invitedDate: member.$createdAt,
                        };
                    });

                // Save to store for later use
                this.teamMembers = membersWithStatus;
                saveToSession('teamMembers', membersWithStatus);
                return membersWithStatus;
                
            } catch (error) {
                console.error('Failed to fetch team members with status:', error);
                throw error;
            }
        },

        // Add method to handle guest Kurtz state
        setGuestKurtz(email) {
            this.isGuestKurtz = true;
            this.email = email;
            sessionStorage.setItem('kurtz_email', email);
        }

    },
});

// Add debounce utility
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}