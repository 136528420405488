<template>
  <div class="max-w-4xl mx-auto p-6 bg-white">
    <p class="text-sm text-gray-600 mb-8 text-center">
      <span class="font-semibold uppercase">Directions:</span> The items that follow reflect values associated with effective organizational operations. Select the number that indicates your opinion of how each value relates to your <span class="font-semibold underline italic">ideal</span> (not current) workplace.

   
    
    </p>



    <!-- Questions Form -->
    <div class="space-y-8">
      <!-- Each Question Card -->
      <div
        v-for="(question, index) in questions"
        :key="index"
        class="p-4 bg-gray-100 border rounded-lg shadow-sm"
      >
        <h2 class="text-lg font-bold text-gray-700 mb-4">{{ question.title }}</h2>

        <div class="flex justify-between items-center">
          <p class="text-sm text-gray-600">{{ question.lowLabel }}</p>
          <!-- Button Rating (1-7) -->
          <div class="flex items-center space-x-2">
            <button
              v-for="i in 7"
              :key="i"
              @click="question.selected = i"
              :class="[
                'px-4 py-2 rounded-lg text-sm font-medium',
                question.selected === i
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300',
              ]"
              type="button"
            >
              {{ i }}
            </button>
          </div>
          <p class="text-sm text-gray-600">{{ question.highLabel }}</p>
        </div>
      </div>
    </div>

    <!-- Total Score Display -->
    <div class="mt-10 flex justify-end">
      <div class="p-4 bg-blue-500 text-white rounded-lg shadow-sm w-full text-center max-w-4xl">
        <h2 class="text-lg font-bold text-center mb-4">Total of the numbers chosen above</h2>
        <input 
          v-model="manualTotalScore"
          type="number"
          class="w-24 p-2 text-center text-black rounded"
          min="0"
        />
      </div>
    </div>

    <!-- Submit Button -->
    <div class="flex justify-end  mt-8">
      <button
        @click="validateAndSubmit"
        class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits } from "vue";
import { useAuthStore } from "../../stores/auth";

const emit = defineEmits(["valid-submit"]);

const authStore = useAuthStore();
const manualTotalScore = ref(0);

// Questions with labels for low and high values
const questions = ref([
  { title: "1. Innovation", lowLabel: "Cautious", highLabel: "Risk taking", selected: 0 },
  {
    title: "2. Focus",
    lowLabel: "Concentrate on details",
    highLabel: "Big picture oriented",
    selected: 0,
  },
  {
    title: "3. Task Emphasis",
    lowLabel: "It's all about how we get there",
    highLabel: "It’s all about where we end up",
    selected: 0,
  },
  {
    title: "4. Cooperation",
    lowLabel: "Individual effort counts",
    highLabel: "Team effort counts",
    selected: 0,
  },
  {
    title: "5. Work Atmosphere",
    lowLabel: "Easygoing",
    highLabel: "Aggressive",
    selected: 0,
  },
  {
    title: "6. Goals",
    lowLabel: "Maintain status quo",
    highLabel: "Constantly grow",
    selected: 0,
  },
]);

// Remove totalScore computed property

// Watch for changes in questions array and manualTotalScore
watch(
  [questions, manualTotalScore],
  ([newQuestions, newTotalScore]) => {
    authStore.kurtzResults.organizationalValues = {
      totalScore: newTotalScore,
      questions: newQuestions,
    };
  },
  { deep: true }
);

// Simplify validateAndSubmit to skip validation
const validateAndSubmit = () => {
  // Remove validation and just submit
  submitSurvey();
};

// Simplify submitSurvey
const submitSurvey = () => {
  emit("valid-submit", questions.value);
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
