<template>
    <div class="min-h-screen flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">



        <div class="max-w-md w-full space-y-8">
            <Logo />
            <div>
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Forgot Password
                </h2>
                <p class="mt-2 text-center text-sm text-gray-600">
                    Enter your email address to receive a password reset link.
                </p>
            </div>
            <form @submit.prevent="handleForgotPassword" class="mt-8 space-y-6 bg-white p-8 rounded-lg shadow-md">
                <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">Email Address</label>
                    <input v-model="email" id="email" type="email" placeholder="Enter your email"
                        class="mt-1 block w-full rounded-md p-2  sm:text-sm" required />
                </div>

                <button type="submit"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Send Reset Link
                </button>

                <p v-if="authStore.success" class="text-green-500 mt-4 text-center">{{ authStore.success }}</p>
            </form>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import Logo from '@/components/Logo.vue';

export default {
    setup() {
        const email = ref('');
        const authStore = useAuthStore();

        const handleForgotPassword = async () => {
            try {
                await authStore.sendPasswordReset(email.value);
            } catch (error) {
                console.error('Failed to send reset link:', error);
            }
        };

        return {
            email,
            authStore,
            handleForgotPassword,
        };
    },
};
</script>