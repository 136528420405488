import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import PolicyAgreement from '@/components/PolicyAgreement.vue';
import AcceptPolicy from '@/components/AcceptPolicy.vue';
import Verify from '@/views/Verify.vue';
import { account } from '@/lib/appwrite';
import InviteTeamMember from '@/components/InviteTeamMember.vue';
import InviteCandidate from '@/components/InviteCandidate.vue';
import RedirectPolicy from '@/components/RedirectPolicy.vue';
import SetupPassword from '@/components/SetupPassword.vue';
import ForgotPassword from '@/components/ForgotPassword.vue';
import ResetPassword from '@/components/ResetPassword.vue';
import Kurtz from '@/views/Kurtz.vue';
import SubmissonKurtz from '@/components/Kurtz/Submission.vue';
import UserAcceptanceStatus from '@/components/UserAcceptanceStatus.vue';
import KurtzResults from '@/components/Admin/KurtzResults.vue';
import KurtzResultDetail from '@/components/Admin/KurtzResultDetail.vue'; // Import the new KurtzResultDetail component
import { useAuthStore } from '@/stores/auth'; // Add this import

const routes = [
    { path: '/', name: 'Home', component: Home },
    {
        path: '/policy-agreement', name: 'PolicyAgreement', component: PolicyAgreement,
        meta: {
            title: 'Policy Agreement',
            requiresAuth: true
        }
    },
    { path: '/accept-policy', name: 'Accept Policy', component: AcceptPolicy, meta: { title: 'Accept Policy', requiresAuth: true } },
    { path: '/setup-password', name: 'SetupPassword', component: SetupPassword, meta: { title: 'Setup Password', requiresAuth: true } },
    {
        path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword,
    },
    {
        path: '/policy-acceptance-status', name: 'UserAcceptanceStatus', component: UserAcceptanceStatus,
    },
    {
        path: '/kurtz-results', name: 'KurtzResults', component: KurtzResults, meta: { title: 'Kurtz Results', requiresAuth: true }

    },
    { path: '/kurtz-results/:id', name: 'KurtzResultDetail', component: KurtzResultDetail, props: true },
    {
        path: '/kurtz/submission', name: 'SubmissonKurtz', component: SubmissonKurtz,
        meta: {
            title: 'Submission',
            requiresAuth: true
        }

    },
    {
        path: '/reset-password', name: 'ResetPassword', component: ResetPassword, meta: { title: 'Reset Password' }
    },
    {
        path: '/kurtz', name: 'Kurtz', component: Kurtz, meta: { title: 'Kurtz', requiresAuth: true },
    },
    { path: '/verify', name: 'Verify', component: Verify, meta: { title: 'Verify', requiresAuth: true } },
    {
        path: '/invite-team-member',
        name: 'InviteTeamMember',
        component: InviteTeamMember,
        meta: {
            title: 'Invite Team Member',
            requiresAuth: true
        }
    },
    {
        path: '/invite-candidate',
        name: 'InviteCandidate',
        component: InviteCandidate,
        meta: {
            title: 'Invite Candidate',
            requiresAuth: true
        }
    },
    {
        path: '/confirmation/:userType',
        name: 'RedirectPolicy',
        component: RedirectPolicy,
        meta: {
            title: 'Confirmation',
        }
    },
    {
        path: '/recent-hires',
        name: 'RecentHires',
        component: () => import('../views/RecentHiresView.vue'),
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/kurtz/audit',
        name: 'KurtzAudit',
        component: () => import('@/components/Admin/KurtzInvitationsAudit.vue'),
        meta: { requiresAuth: true, requiresAdmin: true }
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Simplify the navigation guard to consistently check auth
router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    
    // Define public routes that don't require auth
    const publicRoutes = ['Home', 'ForgotPassword', 'ResetPassword'];
    
    // Allow public routes
    if (publicRoutes.includes(to.name)) {
        next();
        return;
    }

    // Check authentication for protected routes
    try {
        const session = await account.getSession('current');
        if (!session) {
            throw new Error('No session');
        }
        next();
    } catch (error) {
        console.warn('Authentication check failed:', error);
        next({
            name: 'Home',
            query: {
                redirect: to.fullPath,
                error: 'auth_required'
            }
        });
    }
});

export default router;