import { Client, Databases, Account, Teams, ID, Storage, Query, Functions } from 'appwrite';

const client = new Client();

client
    .setEndpoint('https://app.protomentum.com/v1')
    .setProject('66f176c9000e2620795b')

const database = new Databases(client);
const account = new Account(client);
const teams = new Teams(client);
const storage = new Storage(client);
const functions = new Functions(client);

export { client, database, account, teams, ID, storage, Query, functions };