<template>
    <div class=" max-w-4xl mx-auto bg-white rounded-xl shadow-md space-y-4">
        <h2 class="text-xl font-semibold">Company Policies</h2>
        <p>Read through the following policies before proceeding:</p>
        <div class="policy-content">
            <p>... Policy text goes here ...</p>
        </div>

        <div class="flex items-start">
            <input v-model="isAgreed" type="checkbox" class="mr-2" />
            <label>By checking this box, I confirm that I have read and agree to the above policies and
                procedures.</label>
        </div>

        <div>
            <button @click="submitAgreement" :disabled="!isAgreed"
                class="mt-4 bg-green-500 text-white font-bold py-2 px-4 rounded disabled:opacity-50">
                Confirm Agreement
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { database } from '@/lib/appwrite';

const isAgreed = ref(false);

const submitAgreement = async () => {
    try {
        await database.createDocument('613f3b3d5f5b7', {
            isAgreed: isAgreed.value,
            timestamp: new Date().toISOString(),
        });
        $router.push('/confirmation');
    } catch (error) {
        console.error(error);
    }
};
</script>