<template>

    <div class="flex  flex-1 flex-col justify-center -mt-20 px-6 py-12 lg:px-8 bg-white rounded-xl">
    
        <div class="text-center mb-10">
            <h1 class="text-4xl font-bold tracking-tight text-gray-500 sm:text-6xl">
                Welcome to the Candidate Portal!
            </h1>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" @submit.prevent="authStore.login(authStore.email, authStore.password)"
                method="POST">
                <div>
                    <label for="email" class="block text-sm font-medium leading-6 text-gray-700">Email address</label>
                    <div class="mt-2">
                        <input id="email" name="email" type="email" autocomplete="email" required=""
                            v-model="authStore.email"
                            class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-protocase-500 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div>
                    <div class="flex items-center justify-between">
                        <label for="password" class="block text-sm font-medium leading-6 text-gray-700">Password</label>
                        <div class="text-sm">
                            <router-link to="/forgot-password"
                                class="font-semibold text-protocase-400 hover:text-protocase-300">
                                Forgot
                                password?
                                </router-link>
                        </div>
                    </div>
                    <div class="mt-2">
                        <input id="password" name="password" type="password" autocomplete="current-password" required=""
                            v-model="authStore.password"
                            class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-protocase-500 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div>
                    <button type="submit"
                        class="flex w-full justify-center rounded-md bg-protocase-500 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-50 shadow-sm hover:bg-protocase-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-protocase-500">
                        Sign in
                    </button>
                </div>
            </form>


            <p v-if="authStore.error" class="mt-4 text-red-700 font-semibold bg-red-200 rounded p-2">
                {{ authStore.error }}
            </p>
            <p v-if="authStore.success" class="mt-4 text-green-700 font-semibold bg-green-200 rounded p-2">
                {{ authStore.success }}
            </p>


        </div>
    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();

const urlParams = new URLSearchParams(window.location.search);
const secret = urlParams.get("secret");
const userId = urlParams.get("userId");

onMounted(() => {
    if (secret && userId) {
        authStore.loginWithMagicURL(secret, userId);
    }
});



</script>