<template>
  <div class="max-w-4xl mx-auto p-6 bg-white ">
      <h1 class="text-2xl font-bold mb-6 text-center">Communication Exercises Completed</h1>
      <!-- thank you message -->
      <div class="text-center">
          <p class="text-lg text-gray-700">
              Thank you for dedicating time to complete these exercises🎉 We understand that our process may feel
              lengthy, but it’s designed to ensure we find the best fit for both you and our team.
              Our evaluation process takes a comprehensive approach, considering all aspects of your application to
              determine the strongest fit with our team’s culture. This includes the communication exercises, your
              PrevueHR results, answers to application questions, and, of course, your experience!
              <br>
              <br>
              We aim to provide an update on your application within two weeks. In the meantime, please keep an eye on
              your email for any additional requests or updates from our Human Resources Team.
          </p>
      </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "../../stores/auth";
const authStore = useAuthStore();
const router = useRouter();
onMounted(() => {
  console.log('Communication exercises completed');
  authStore.updatePrefs(
      {
          communication_exercises_completed: true
      }
  )
});
</script>
<style scoped>
/* Add any custom styles if needed */
</style>