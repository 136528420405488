<template>
  <!-- Main 3 column grid -->
  <div
    class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 lg:mt-0 mt-20"
    v-if="authStore.isAuthenticated"
  >
    <!-- Left column -->
    <div class="grid grid-cols-1 gap-4 lg:col-span-2">
      <!-- Welcome panel -->

      <section aria-labelledby="profile-overview-title" v-if="authStore.isAuthenticated">
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
          <div class="bg-blue-700/80 text-white p-6">
            <div class="sm:flex sm:items-center sm:justify-between">
              <div class="sm:flex sm:space-x-5">
                <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p class="text-sm font-medium">
                    <span v-if="user.role === 'admin' || user.role === 'owner'">
                      Welcome to the Admin Portal!
                    </span>
                  </p>
                  <p class="capitalize text-xl font-bold sm:text-2xl">
                    {{ user.name }}
                  </p>
                  <p class="capitalize text-sm font-medium">
                    {{ user.role }}
                  </p>
                  <!-- teams -->
                  <!-- <div class="mt-2 flex items-center justify-center sm:justify-start">
                    <div class="flex items-start text-sm text-gray-500">
                      <span class="font-medium text-gray-900">Teams:</span>
                      <ul class="ml-2 list-disc">
                        <li v-for="team in user.teams" :key="team">
                          {{ team }}
                        </li>
                      </ul>
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- <div class="mt-5 flex justify-center sm:mt-0">
                <a href="#"
                  class="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">View
                  profile</a>
              </div> -->
            </div>
          </div>
          <div
            v-if="user.role === 'admin' || user.role === 'owner'"
            class="grid divide-y divide-gray-200 border-t border-gray-200 bg-white sm:divide-x sm:divide-y-0"
          >
            <!-- admin action button such as invite user -->
            <div
              class="flex flex-col sm:flex-row text-center p-6 space-y-3 sm:space-y-0 sm:space-x-3"
            >
              <router-link
                to="/invite-team-member"
                class="group inline-flex items-center bg-green-500 text-white p-2 rounded-lg hover:bg-green-800"
              >
                <span class="text-sm font-medium">Invite Employee</span>
              </router-link>
              <!-- invite Candidate -->
              <router-link
                to="/invite-candidate"
                class="group inline-flex items-center bg-green-500 text-white p-2 rounded-lg hover:bg-green-800"
              >
                <span class="text-sm font-medium">Invite Candidate</span>
              </router-link>

              <!-- kurtz -->
              <router-link
                :to="{ name: 'Kurtz' }"
                class="group inline-flex items-center bg-green-500 text-white p-2 rounded-lg hover:bg-green-800"
              >
                <span class="text-sm font-medium">Review Kurtz Kit</span>
              </router-link>
            </div>
          </div>
          <!-- if label has candidate, show candidate action button -->
          <div
            v-if="user.role === 'candidate'"
            class="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-white sm:grid-cols-3 sm:divide-x sm:divide-y-0"
          >
            <div class="flex text-center p-6 space-y-3 sm:space-y-0 sm:space-x-3">
              <!-- kurtz -->
              <router-link
                v-if="!communicationExercisesCompleted"
                to="/kurtz"
                class="group inline-flex items-center bg-green-500 text-white p-2 rounded-lg hover:bg-green-800"
              >
                <span class="text-sm font-medium"> Communication Exercises</span>
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <!-- Actions panel -->
      <section
        aria-labelledby="quick-links-title"
        v-if="user.role === 'admin' || user.role === 'owner'"
      >
        <div
          class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0"
        >
          <h2 class="sr-only" id="quick-links-title">Quick links</h2>
          <div
            v-for="(action, actionIdx) in actions"
            :key="action.name"
            :class="[
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1
                ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                : '',
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500',
            ]"
          >
            <div>
              <span
                :class="[
                  action.iconBackground,
                  action.iconForeground,
                  'inline-flex rounded-lg p-3 ring-4 ring-white',
                ]"
              >
                <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-8">
              <h3 class="text-lg font-medium">
                <a :href="action.href" class="focus:outline-none">
                  <!-- Extend touch target to entire panel -->
                  <span class="absolute inset-0" aria-hidden="true" />
                  {{ action.name }}
                </a>
              </h3>
              <p class="mt-2 text-sm text-gray-500">{{ action.description }}</p>
            </div>
            <span
              class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
                />
              </svg>
            </span>
          </div>
        </div>
      </section>
    </div>

    <!-- Right column -->
    <div class="grid grid-cols-1 gap-4">
      <!-- Announcements -->
      <section
        aria-labelledby="announcements-title"
        v-if="user.role === 'admin' || user.role === 'owner'"
      >
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="p-6">
            <h2 class="text-base font-medium text-gray-900" id="announcements-title">
              Send login link
            </h2>
            <div class="mt-6">
              <p class="text-sm text-gray-500">
                Select a user from the list below to send a login link.
              </p>
            </div>
            <div class="mt-6 flow-root">
              <!-- select user from list of recent hires -->
              <select
                v-model="selectedUser"
                class="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="" disabled>Select User</option>
                <option
                  v-for="person in sortedRecentHires"
                  :key="person.handle"
                  :value="person"
                >
                  {{ person.name }}
                </option>
              </select>
            </div>

            <div class="mt-6">
              <button
                type="button"
                :disabled="!selectedUser"
                @click="authStore.sendMagicURL(selectedUser.handle)"
                class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                :class="{ 'cursor-not-allowed opacity-50': !selectedUser }"
              >
                Send Login Link
              </button>

              <!-- show success message -->
              <div v-if="authStore.success" class="text-green-500 mt-4">
                Login Link sent to {{ selectedUser?.name }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <!-- User not authenticated -->
  <Login v-else />
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  AcademicCapIcon,
  BanknotesIcon,
  Bars3Icon,
  BellIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import Login from "../components/Login.vue";
import RecentHires from "../components/RecentHires.vue";

const authStore = useAuthStore();
const router = useRouter();

const user = computed(() => {
  if (!authStore.isAuthenticated) {
    return {
      name: "N/A",
      email: "N/A",
      role: "N/A",
    };
  }
  return {
    name: authStore.user.name || "N/A",
    email: authStore.user.email || "N/A",
    role:
      authStore.user.labels && authStore.user.labels.length > 0
        ? authStore.user.labels[0]
        : "N/A",
    teams: authStore.user.teams || [],
    prefs: authStore.user.prefs || {},
    joinedAt: authStore.user.registration,
  };
});

const communicationExercisesCompleted = computed(() => {
  return user.value.prefs.communication_exercises_completed === true;
});

// if user is candidate, redirect to kurtz
onMounted(() => {
  if (user.value.role === "candidate" && !communicationExercisesCompleted.value) {
    router.push({ name: "Kurtz" });
  }
});

const actions = [
  // view all recent hires
  {
    icon: UsersIcon,
    name: "Recent Hires",
    description: "View all recent hires",
    href: "recent-hires",
    iconForeground: "text-green-700",
    iconBackground: "bg-green-50",
  },

  {
    icon: UsersIcon,
    name: "Users who accepted the policy",
    description: "View users who accepted the policy",
    href: "policy-acceptance-status",
    iconForeground: "text-sky-700",
    admin: true,
    iconBackground: "bg-sky-50",
  },

  {
    icon: AcademicCapIcon,
    name: "Kurtz Results",
    href: "kurtz-results",
    admin: true,
    description: "View Kurtz results",
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  // kurtz/audit
  {
    icon: AcademicCapIcon,
    name: "Kurtz Audit",
    href: "kurtz/audit",
    admin: true,
    description: "View Kurtz audit",
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
];

const selectedUser = ref(null);

// compute recent hires
const recentHires = computed(() => {
  return authStore.teamMembers.map((member) => {
    return {
      name: member.userName,
      handle: member.userEmail,
      confirm: member.confirm,
      roles: member.roles,
      teamName: member.teamName,
      id: member.$id,
      createdAt: member.$created,
    };
  });
});

const sortedRecentHires = computed(() => {
  return recentHires.value.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
});

const limitedRecentHires = computed(() => {
  return sortedRecentHires.value.slice(0, 5); // Show only last 5 users
});
</script>
