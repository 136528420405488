<template>
  <div class="mt-20 max-w-2xl mx-auto p-6 bg-white rounded-md shadow-lg">
    <h2 class="text-2xl font-semibold mb-4">
      <span v-if="authStore.userType === 'admin'">Welcome to the Candidate Portal!</span>
      <span v-else-if="authStore.userType === 'candidate'"
        >Welcome to the Communication Exercise Portal!</span
      >
    </h2>

    <div v-if="authStore.error" class="text-red-500">
      {{ authStore.error }}
      <SendMagicURL />
    </div>

    <!-- success -->
    <div v-else-if="authStore.success" class="text-green-500">
      {{ authStore.success }}
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import SendMagicURL from "./SendMagicURL.vue";

const authStore = useAuthStore(); // To access authStore state
const route = useRoute(); // To get query parameters
const router = useRouter(); // To redirect after success

onMounted(async () => {

  // Get query parameters
  const { membershipId, userId, secret, teamId } = route.query;
  const userType = route.params.userType; // Access userType from the URL

  // Store userType in authStore
  authStore.userType = userType;

  // Validate query parameters
  if (membershipId && userId && secret && teamId) {
    try {
      // if userType is candidate, accept team invitation
      if (userType === "candidate") {
        await authStore.acceptTeamInvitation(teamId, membershipId, userId, secret);
        if (!authStore.error) {
          router.push("/kurtz");
        }
      }
    } catch (error) {
      console.log("Failed to accept team invitation.", error);
      authStore.error = "Failed to accept team invitation.";
    }
  } else {
    console.log("Invalid setup link.");
    authStore.error = "Invalid setup link.";
  }
});
</script>
