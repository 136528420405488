<template>
  <div class="flex items-center justify-center h-screen bg-gray-100">
    <div class="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
      <h2 class="text-2xl font-bold mb-4">Email Verification</h2>
      <div v-if="loading" class="text-gray-700">Verifying...</div>
      <div v-else-if="error" class="text-red-500">{{ sanitizedError }}</div>
      <div v-else class="text-green-500">Your email has been verified successfully!</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { account } from '@/lib/appwrite';

const route = useRoute();
const loading = ref(true);
const error = ref(null);

const sanitizedError = computed(() => {
  if (!error.value) return null;
  return 'Email verification failed. Please try again or contact support.';
});

const verifyEmail = async () => {
  const userId = route.query.userId;
  const secret = route.query.secret;

  if (!userId || !secret) {
    error.value = 'Invalid verification link';
    loading.value = false;
    return;
  }

  try {
    await account.updateVerification(userId, secret);
  } catch (err) {
    console.error('Verification error:', err);
    error.value = 'Verification failed';
  } finally {
    loading.value = false;
  }
};

onMounted(verifyEmail);
</script>

<style scoped>
/* Add any additional styles you need here */
</style>